import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  dmpconnect,
  dmpconnectCPxConfiguration,
  dmpconnectInit,
  getDmpconnectConnectorConfigReducer,
  getDmpconnectEsConfigReducer,
  getDmpconnectTSEConfigReducer,
  getDmpconnectINSiConfigReducer,
  dmpconnectMonitoring, dmpconnectInteropCodes,
} from 'dmpconnectjsapp-base/reducers';
import getDmpconnectPersistedConnectorConfiguration
  from 'dmpconnectjsapp-base/reducers/dmpconnectPersistedConnectorConfiguration';
import {
  dmpconnectApplication, dmpconnectDisplayConfiguration,
  dmpConnectPersistedAppConfiguration,
  dmpconnectUser,
} from '../dmpconnect/reducers';
import env from '../envVariables';
import {
  connectorConfigFromEnv,
  esConfigFromEnv, insiConfigFromEnv, persistedConnectorConfigFromEnv,
  tseConfigFromEnv,
} from './configFromEnv';

export const storages = [];
function customPersistReducer(config, reducer) {
  storages.push(config.key);
  return persistReducer(config, reducer);
}

const dmpconnectConnectorConfig = getDmpconnectConnectorConfigReducer(connectorConfigFromEnv);
const dmpconnectPersistedConnectorConfig = getDmpconnectPersistedConnectorConfiguration(persistedConnectorConfigFromEnv);
const dmpconnectESConfiguration = getDmpconnectEsConfigReducer(esConfigFromEnv);
const dmpconnectTSEConfiguration = getDmpconnectTSEConfigReducer(tseConfigFromEnv);
const dmpconnectINSiConfiguration = getDmpconnectINSiConfigReducer(insiConfigFromEnv);

const rootPersistConfig = {
  key: 'insi_consult_root',
  storage,
  whitelist: [
    'dmpconnectUser',
    'dmpconnectCPxConfiguration',
    'dmpConnectPersistedAppConfiguration',
    'dmpconnectPersistedConnectorConfiguration',
    'dmpconnectTSEConfiguration',
  ],
  version: 2,
  migrate: createMigrate({
    // migration from version 1 to version 2
    2: state => ({
      ...state,
      dmpconnectConnectorConfig: {
        ...state.dmpconnectConnectorConfig,
        tlsiServerName: env.REACT_APP_TLSI_SERVER_NAME || env.REACT_APP_DMP_SERVER_NAME,
      },
    }),
    3: state => ({
      ...state,
      dmpConnectPersistedAppConfiguration: {
        ...state.dmpConnectPersistedAppConfiguration,
        noExportToIframeParent: Number(env.REACT_APP_DO_NOT_EXPORT_TO_IFRAME_PARENT) === 1,
      },
    }),
  }),
};

if (Number(env.REACT_APP_DEBUG) === 1) {
  rootPersistConfig.whitelist.push('dmpconnectConnectorConfig');
  rootPersistConfig.whitelist.push('dmpconnectDisplayConfiguration');
  rootPersistConfig.whitelist.push('dmpconnectINSiConfiguration');
}


const dmpconnectPersistConfig = {
  key: 'insi_consult_commands',
  storage,
  version: 1,
};
const dmpconnectESPersistConfig = {
  key: 'insi_consult_es_config',
  storage,
  version: 1,
};
const interopCodesPersistConfig = {
  key: 'efficience_interop_codes',
  storage,
};


const createRootReducer = history => combineReducers({
  router: connectRouter(history),

  // external connector
  dmpconnect: customPersistReducer(dmpconnectPersistConfig, dmpconnect),
  dmpconnectInit,

  dmpconnectDisplayConfiguration,
  dmpconnectConnectorConfig,
  dmpconnectCPxConfiguration,
  dmpconnectESConfiguration: customPersistReducer(dmpconnectESPersistConfig, dmpconnectESConfiguration),
  dmpconnectTSEConfiguration,
  dmpconnectINSiConfiguration,
  dmpconnectPersistedConnectorConfiguration: dmpconnectPersistedConnectorConfig,
  dmpconnectInteropCodes: customPersistReducer(interopCodesPersistConfig, dmpconnectInteropCodes),

  // application
  dmpconnectUser,
  dmpconnectMonitoring,
  dmpconnectApplication,
  dmpConnectPersistedAppConfiguration,
  // dmpconnectPDFs,
});

export default history => customPersistReducer(rootPersistConfig, createRootReducer(history));
