import {
  setESConfiguration, setGlobalConfiguration,
  setModalError,
  setPersistedConnectorConfiguration,
  setTseConfiguration,
} from 'dmpconnectjsapp-base/actions';
import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { push } from 'connected-react-router';
import React from 'react';
import {
  getApiType,
  getConfigurationValue,
  getDmpconnectPersistedConnectorConfig,
} from 'dmpconnectjsapp-base/helpers/accessors';
import { generateAndSaveToken } from 'dmpconnectjsapp-base/sagas/utilsSagas';
import { API_TYPES } from 'dmpconnectjsapp-base/constants';
import { b64DecodeUnicode, isUrlValid } from '../utils/dataUtils';
import { createError, createErrorDetails, createModalError } from '../errors';
import { errorTypes } from '../errors/errorConfiguration';
import { softwareErrors } from '../errors/errorConstants';
import {
  clearLoginReferer,
  setCheckIdentityParams,
  setExportId,
  setInsParams, setLoginTab,
  setPanel,
  setPersistedAppConfiguration,
} from '../actions';
import { handleLoginCheck } from './legacyLocationChange';
import { PANELS } from '../constants';
import env from '../../envVariables';
import { getDmpconnectESConfiguration } from '../helpers';

const handleEsSettings = function* (settings) {
  const {
    id,
    locationName,
    activitySector,
    practiceSetting,
    finess,
  } = settings;

  // const activitySectors = yield select(getInteropCodesFromState, 'activitySectors');
  // const practiceSettings = yield select(getInteropCodesFromState, 'practiceSettings');

  const validator = yup.object({
    id: yup.string().required('L\'identifiant est manquant'),
    locationName: yup.string().required('Le nom de l\'établissement est manquant'),
    activitySector: yup.string()
      // .oneOf(
      //   activitySectors.map(ps => ps.code),
      //   ({ values }) => `Le secteur d'activité doit être une des valeurs suivantes : ${values}`,
      // )
      .required('Le secteur d\'activité est manquant'),
    practiceSetting: yup.string()
      // .oneOf(
      //   practiceSettings.map(ps => ps.code),
      //   ({ values }) => `Le cadre d'exercice doit être une des valeurs suivantes : ${values}`,
      // )
      .required('Le cadre d\'exercice est manquant'),
    finess: yup.string(),
  });

  try {
    validator.validateSync(settings, { abortEarly: false });

    yield put(setESConfiguration('es_id', id));
    yield put(setESConfiguration('practiceLocationName', locationName));
    yield put(setESConfiguration('activitySector', activitySector));
    yield put(setESConfiguration('practiceSetting', practiceSetting));
    yield put(setESConfiguration('es_finess', finess));

    toast.dismiss('es-config');
    toast.success('Configuration de l\'établissement mise à jour', {
      autoClose: 2000,
      toastId: 'es-config',
    });
  } catch (validationError) {
    const details = [
      createErrorDetails('Errors', validationError.inner.map(error => ({
        field: error.path,
        value: error.value,
        error: error.message,
      }))),
      createErrorDetails('provided', settings),
    ];
    const modalError = createModalError({
      i_apiErrorType: errorTypes.SoftwareErrors,
      i_apiErrorCode: softwareErrors.INVALID_ES_SETTINGS,
    }, details);

    yield put(setModalError(modalError));
  }
};

function* checkAndSetUrl(url, stateKey, successMessage, errorMessage) {
  try {
    if (isUrlValid(url)) {
      yield put(setPersistedAppConfiguration(stateKey, url));
      toast.dismiss(stateKey);
      toast.success(successMessage, {
        autoClose: 2000,
        toastId: stateKey,
      });
    } else {
      throw new Error('Url is invalid');
    }
  } catch (e) {
    toast.error((
      <div>
        <div className="font-weight-bold">Erreur</div>
        <span>{errorMessage}</span>
      </div>
    ), {
      autoClose: false,
      toastId: stateKey,
    });
  }
}

export function* processGenericParams(parameters, loggedIn) {
  const { params } = parameters;
  const connectorConfig = yield select(getDmpconnectPersistedConnectorConfig);
  const onlyGeneratedConnectorJWT = getConfigurationValue('onlyGeneratedConnectorJWT', connectorConfig);
  const esConfig = yield select(getDmpconnectESConfiguration);

  if (params) {
    let decodedParams;
    let parsedParams;
    try {
      // base64 decode
      decodedParams = b64DecodeUnicode(params);
      // parse json
      parsedParams = JSON.parse(decodedParams);

      const {
        vitale: readVitaleCard,
        checkIdentity,
        search,
        esSettings,
        exportId,
        tseId,
        exportUrl,
        batchImportUrl,
        batchExportUrl,
        cpxInfoExportUrl,
        jwt,
        jwtSecret,
        loginCheck,
        authenticationTab,
      } = parsedParams;

      // check exportId param
      if (exportId) {
        yield put(setExportId(exportId));
        console.log('nouvel exportId pris en compte : ', exportId);
      }

      // exportUrl
      if (exportUrl) {
        yield call(
          checkAndSetUrl,
          exportUrl,
          'resultExportUrl',
          'Adresse d\'export mise à jour',
          'L\'adresse d\'export fournie n\'est pas valide',
        );
      }

      // check batchimporturl param
      if (batchImportUrl) {
        yield call(
          checkAndSetUrl,
          batchImportUrl,
          'batchImportUrl',
          'Adresse d\'import des lots d\'identités mise à jour',
          'L\'adresse d\'import des lots d\'identités fournie n\'est pas valide',
        );
      }
      // check batchexporturl param
      if (batchExportUrl) {
        yield call(
          checkAndSetUrl,
          batchExportUrl,
          'batchExportUrl',
          'Adresse d\'export des lots d\'identités mise à jour',
          'L\'adresse d\'export des lots d\'identités fournie n\'est pas valide',
        );
      }

      // check cpxInfoExportUrl param
      if (cpxInfoExportUrl) {
        yield call(
          checkAndSetUrl,
          cpxInfoExportUrl,
          'cpxInfoExportUrl',
          'Adresse d\'export des informations de la carte CPx mise à jour',
          'L\'adresse d\'export des informations de la carte CPx fournie n\'est pas valide',
        );
      }

      // rest jwt connector
      if (jwt) {
        yield put(setPersistedConnectorConfiguration('connectorJWT', jwt));
        if (readVitaleCard || search || checkIdentity) {
          yield put(clearLoginReferer());
        }
      } else if (jwtSecret && !onlyGeneratedConnectorJWT) {
        yield call(generateAndSaveToken, jwtSecret);
        if (readVitaleCard || search || checkIdentity) {
          yield put(clearLoginReferer());
        }
      }

      if (loginCheck) {
        yield call(handleLoginCheck, loginCheck);
      }

      // es settings
      if (esSettings) {
        yield call(handleEsSettings, esSettings);
      }
      // check tseid param
      if (tseId) {
        yield put(setTseConfiguration('whoami', tseId));
        yield put(setTseConfiguration('active', true));
        toast.success('Identifiant utilisateur TSE mis à jour', {
          autoClose: 2000,
          toastId: 'whoami',
        });
      }

      if (authenticationTab) {
        yield put(setLoginTab(authenticationTab));
        const apiType = yield select(getApiType);
        if (env.REACT_APP_API_TYPE === API_TYPES.REST && esConfig.cpxLoginActive) {
          if (authenticationTab === 'es' && apiType === API_TYPES.WS) {
            yield put(setGlobalConfiguration('apiType', API_TYPES.REST));
            yield put(setGlobalConfiguration('tlsiServerName', Number(env.REACT_APP_PRODUCTON_MODE) === 1
              ? 'https://services-ps-tlsm.ameli.fr'
              : 'https://qualiflps-services-ps-tlsm.ameli.fr'));
          }
          if (authenticationTab === 'cpx' && apiType === API_TYPES.REST) {
            yield put(setGlobalConfiguration('apiType', API_TYPES.WS));
            yield put(setGlobalConfiguration('tlsiServerName', Number(env.REACT_APP_PRODUCTON_MODE) === 1
              ? 'https://services-ps.ameli.fr/lps'
              : 'https://qualiflps.services-ps.ameli.fr'));
          }
        }
      }

      if (loggedIn) {
        if (readVitaleCard === true) {
          yield put(setPanel(PANELS.FROM_VITALE_CARD));
          yield put(push('/search/'));
        } else if (search) {
          yield put(setInsParams(search));
          yield put(setPanel(PANELS.MANUAL));
          yield put(push('/search/'));
        } else if (checkIdentity) {
          yield put(setCheckIdentityParams(checkIdentity));
          yield put(setPanel(PANELS.VALIDATION));
          yield put(push('/search/'));
        }
      }
    } catch (e) {
      console.log('error params', e);
      const error = createError(errorTypes.SoftwareErrors, softwareErrors.PARAMS_INVALID_JSON);
      const details = [
        createErrorDetails('provided', {
          base64: params,
          decoded: decodedParams,
        }),
      ];
      const modalError = createModalError(error, details);
      yield put(setModalError(modalError));
    }
  }
}
